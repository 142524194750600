/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { useState, useEffect } from "react";
import Table from "@mui/joy/Table";
import app from "../../common/sendrequest";
import CommonPopupRender from "../../common/popuprender";
import SearchIcon from "@mui/icons-material/Search";
import { Customer } from "./Customer.type";
import LockResetIcon from "@mui/icons-material/LockReset";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ClearIcon from "@mui/icons-material/Clear";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CalendarTodaySharpIcon from "@mui/icons-material/CalendarTodaySharp";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import Pagination from "@mui/material/Pagination";
import DeleteIcon from "@mui/icons-material/Delete";
import RepartitionIcon from "@mui/icons-material/Repartition";
import {
  Typography,
  Sheet,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Divider,
  Select,
  Option,
  Box,
  LinearProgress,
  Tooltip,
  IconButton,
  Checkbox,
  CircularProgress,
  ListDivider,
  ListItemDecorator,
  Menu,
  MenuItem,
  FormHelperText,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  tabClasses,
  Textarea,
  Link,
  Card,
  ChipDelete,
  ModalOverflow,
  Chip,
  Switch,
  MenuButton,
  Dropdown,
} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { vi } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import {
  Backdrop,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button as MButoon,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import { Package } from "../packages/Package.type";
import configs from "../../config";
import axios, { AxiosError } from "axios";
import "./ListAllCustomer.css";
import { DisplayDate } from "../component/DisplayDate";
import moment from "moment";
import { convertDate } from "../../common/expression";
import { NumericFormatCustom } from "../../common/currencyrender";
type ServerError = { message: string };
export default function ListCustomer() {
  const now = moment();
  const start_month = now.startOf("month").toDate();
  const end_month = now.endOf("month").toDate();
  const sessiondata = JSON.parse(
    sessionStorage.getItem("cus_searchname_next") ||
      `{"searchName":"","package_id":"all","currentpage":1,"month":"","status":"all","domain":"all","count_filter":"all","fromDate":"${start_month}","toDate":"${end_month}"}`
  );
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 900,
        lg: 1280,
        xl: 1800,
      },
    },
  });
  const laptopUI = useMediaQuery(theme.breakpoints.up("lg"));
  const mobileUI = useMediaQuery(theme.breakpoints.down("md"));
  const [rows, setRow] = useState<Customer[]>([]);
  const [popup, setPopUp] = useState<JSX.Element>(<></>);
  const [open, setOpen] = useState<boolean>(false);
  const [currentpage, setCurrentPage] = useState(sessiondata!.currentpage);
  const [layout, setLayout] = useState(false);
  const [month, setMonth] = useState(sessiondata!.month);
  const [count_list, setCountList] = useState<{ _id: string; count: number }[]>(
    []
  );
  const [status, setStatus] = useState(sessiondata!.status);
  const [domain, setDomain] = useState(sessiondata!.domain);
  const [count_filter, setCountFilter] = useState(sessiondata!.count_filter);
  const [searchClick, setsearchClick] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fromDate, setfromDate] = useState<Date | undefined>(
    sessiondata!.fromDate && new Date(sessiondata!.fromDate)
  );
  const [toDate, settoDate] = useState<Date | undefined>(
    sessiondata!.toDate && new Date(sessiondata!.toDate)
  );
  const [searchName, setName] = useState(sessiondata!.searchName);
  const [page, setPage] = useState(0);
  const [package_id, setPackages] = useState<string>(sessiondata!.package_id);
  const [list_package, setListPack] = useState<Package[]>([]);
  const [list_extpackage, setListExtPack] = useState<Package[]>([]);
  const [initPopup, setInitPopup] = useState<any>({
    openFlag: false,
    type: "",
    content: null || "",
    onPass: () => {},
    onCancel: () => {},
    setOpenFlag: (flag: boolean) =>
      setInitPopup({ ...initPopup, openFlag: flag }),
  });
  const [tempSearch, setTempSearch] = useState({
    searchName,
    package_id,
    status,
    domain,
    count_filter,
    fromDate,
    toDate,
  });

  const handleSearch = () => {
    setTempSearch({
      searchName,
      package_id,
      status,
      domain,
      count_filter,
      fromDate,
      toDate,
    });
    setCurrentPage(1);
    setsearchClick(!searchClick);
  };
  let navigate = useNavigate();

  const PopupRender = ({ children }: any) => {
    return (
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setLayout(false);
        }}
      >
        <ModalOverflow sx={{ height: "unset" }}>
          <ModalDialog
            aria-labelledby="basic-modal-dialog-title"
            aria-describedby="basic-modal-dialog-description"
            // sx={{ width: "35vw", minWidth: "400px" }}
            size="md"
            layout={layout ? "fullscreen" : "center"}
          >
            <ModalClose />
            {children}
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    );
  };

  const handleChangePage = (event: any, value: number) => {
    setCurrentPage(value);
    setsearchClick(!searchClick);
  };

  const handleError = (err: any) => {
    setLoading(false);
    if (axios.isAxiosError(err)) {
      const serverError = err as AxiosError<ServerError>;
      if (serverError && serverError.response) {
        setInitPopup({
          ...initPopup,
          openFlag: true,
          type: "fail",
          content: serverError.response.data.message,
        });
      }
    }
  };
  const deleteCustomer = (id: string) => {
    setInitPopup({
      ...initPopup,
      type: "confirmed",
      openFlag: true,
      content: "Bạn có chắc muốn xóa tài khoản này không ?",
      onPass: () =>
        app
          .post(configs.api + "customer/delete/" + id)
          .then(() => {
            setInitPopup({
              ...initPopup,
              openFlag: true,
              type: "success",
              content: "Xóa tài khoản thành công!",
            });
            getData();
          })
          .catch((err) => {
            handleError(err);
          }),
    });
  };

  const resendDomainCustomer = (row: Customer) => {
    setInitPopup({
      ...initPopup,
      type: "confirmed",
      openFlag: true,
      content: `Bạn có chắc muốn gửi email đăng kí tên miền cho tài khoản này không ?`,
      onPass: () =>
        app
          .post(configs.api + "customer/delete/" + row._id)
          .then(() => {
            setInitPopup({
              ...initPopup,
              openFlag: true,
              type: "success",
              content: "Xóa tài khoản thành công!",
            });
            getData();
          })
          .catch((err) => {
            handleError(err);
          }),
    });
  };

  const resetPwd = (row: Customer) => {
    const PwdComponet = () => {
      const [checked, setChecked] = useState(false);
      const [new_password, setPassword] = useState(configs.customer_pwd);
      const handleChange = (e: any) => {
        setPassword(e.target.value);
      };
      const handleSubmitResetPwd = async (
        event: React.FormEvent<HTMLFormElement>
      ) => {
        event.preventDefault();
        setPassword(new_password);
        setLoading(true);
        await app
          .post(configs.api + "customer/reset_password", {
            id: row._id,
            new_password: new_password,
            checked,
          })
          .then(async (data: any) => {
            setLoading(false);
            if (data.data) {
              setInitPopup({
                ...initPopup,
                openFlag: true,
                type: "success",
                content: "Thay đổi mật khẩu thành công",
                onPass: () => {
                  setOpen(false);
                },
              });
            } else {
              setInitPopup({
                ...initPopup,
                openFlag: true,
                type: "fail",
                content: "Có lỗi xảy ra",
              });
            }
          })
          .catch((err) => {
            handleError(err);
          });
      };
      return (
        <>
          <Typography component="h3" id="basic-modal-dialog-title">
            Thay đổi mật khẩu tài khoản
          </Typography>
          <Divider />
          <Typography level="body-lg" id="basic-modal-dialog-description">
            Email : {row.email}
          </Typography>
          <Typography level="body-lg" id="basic-modal-dialog-description">
            Số điện thoại : {row.phone}
          </Typography>
          <form
            onSubmit={handleSubmitResetPwd}
            style={{ width: "30vw", minWidth: "400px" }}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Mật khẩu mới</FormLabel>
                <Input
                  autoFocus
                  required
                  name="new_password"
                  onChange={handleChange}
                  value={new_password}
                />
              </FormControl>
              <Checkbox
                label="Gửi email thông báo cho tài khoản này"
                size="md"
                name="checkbox"
                onChange={(e) => {
                  setChecked(!checked);
                }}
              />
              <Button type="submit">Xác nhận</Button>
            </Stack>
          </form>
        </>
      );
    };
    setOpen(true);
    setPopUp(<PwdComponet />);
  };

  const [openDialog, setOpenDialog] = React.useState(false);
  const [titleDialog, setTitleDialog] = React.useState("");
  const [errMsgDialog, setErrMsgDialog] = React.useState("");
  const [familyIdSelected, setFamilyIdSelected] = React.useState("");
  const [wattingDialog, setWattingDialog] = React.useState(false);
  const [doneFlag, setDoneFlag] = React.useState(false);

  const handleClose = () => {
    if (wattingDialog) return;
    setOpenDialog(false);
    setErrMsgDialog("");
    setDoneFlag(false);
    setWattingDialog(false);
  };

  const handleRestoreFile = async (
    event: React.FormEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (wattingDialog) return;

    setErrMsgDialog("");
    setDoneFlag(false);

    const arrFilesUpload = (
      document.getElementById("fileUpload") as HTMLInputElement
    ).files;

    if (!arrFilesUpload || arrFilesUpload.length < 1) {
      setErrMsgDialog("Chưa chọn file khôi phục");
      return;
    }

    const fileUpload = arrFilesUpload[0];
    const formData = new FormData();
    formData.append("file_restore", fileUpload);
    formData.append("ref_family_id", familyIdSelected);

    const requestUpload = app.post(configs.api + "customer/restore", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    requestUpload.catch((e) => {
      setWattingDialog(false);
      setErrMsgDialog("Có lỗi trong quá trình xử lý");
    });

    setWattingDialog(true);

    const response = await requestUpload;

    if (response.status != 200) {
      setWattingDialog(false);
      setErrMsgDialog("Có lỗi trong quá trình xử lý");
      return;
    }

    setWattingDialog(false);
    setErrMsgDialog("");
    setDoneFlag(true);
  };

  const restoreDB = (rowSelected: Customer) => {
    if (!rowSelected) {
      return;
    }

    setTitleDialog(
      `Dòng họ: ${rowSelected.family.family_name} - ${
        rowSelected.family.family_alias_name +
        rowSelected.family.family_alias_number.toString().padStart(4, "0")
      }`
    );
    setFamilyIdSelected(rowSelected.family._id);
    setOpenDialog(true);
  };

  const packageList = async () => {
    app
      .post(configs.api + "package/list_all")
      .then((response) => setListPack(response.data.original))
      .catch((err) => setListPack([]));
  };

  const packageExtendList = async () => {
    // ((100 - sale_percent_value) * left_day * priceperday) /
    //                   100
    app
      .post(configs.api + "package/list_all", { package_type: "TYPE00002" })
      .then((response) => setListExtPack(response.data.original))
      .catch((err) => setListExtPack([]));
  };

  useEffect(() => {
    getData();
    packageExtendList();
    sessionStorage.setItem(
      "cus_searchname",
      JSON.stringify({
        searchName,
        package_id,
        month,
        currentpage,
        status,
        domain,
        count_filter,
        fromDate,
      })
    );
    sessionStorage.removeItem("cus_searchname_next");
    sessionStorage.removeItem("pending_searchname_next");
  }, [searchClick]);

  useEffect(() => {
    packageList();
  }, []);

  const getData = () => {
    try {
      setLoading(true);
      app
        .post(configs.api + "customer/list_customer", {
          searchstring: tempSearch.searchName,
          package_id: tempSearch.package_id,
          rowsPerpage: 10,
          month: month,
          status: tempSearch.status,
          domain: tempSearch.domain,
          count_filter: tempSearch.count_filter,
          fromday: tempSearch.fromDate,
          today: tempSearch.toDate,
          // month : month.substring(5, 7)+month.substring(2,4),
          currentPage: currentpage - 1,
        })
        .then((data: any) => {
          setPage(
            data.data.package_count.reduce((x: any, y: any) => x + y.count, 0)
          );
          setCountList(data.data.package_count);
          setRow(data.data.original);
          setLoading(false);
        })
        .catch((err: Error) => {
          setPage(0);
          setCountList([]);
          setRow([]);
          setLoading(false);
        });
    } catch (err) {}
  };

  const exportExcel = () => {
    setInitPopup({
      ...initPopup,
      type: "confirmed",
      openFlag: true,
      content: "Bạn có muốn tiếp tục tải file xuống không?",
      onPass: () => {
        axios({
          url: configs.api + "customer/download_excel",
          method: "post",
          data: {
            searchName: tempSearch.searchName,
            package_id: tempSearch.package_id,
            status: tempSearch.status,
            list_package,
            domain: tempSearch.domain,
            fromday: tempSearch.fromDate,
            today: tempSearch.toDate,
            count_filter: tempSearch.count_filter,
          },
          responseType: "blob",
          withCredentials: true,
        })
          .then((response) => {
            const headerLine = response.headers["content-disposition"];
            if (headerLine) {
              const filename = headerLine.split(`"`)[1];
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", filename);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch((error) => {
            handleError(error);
          });
      },
    });
  };

  const resendDomainkey = (row: Customer) => {
    setInitPopup({
      ...initPopup,
      type: "confirmed",
      openFlag: true,
      content: (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography>
            Bạn có muốn gửi mail đăng ký tên miền lại cho khách hàng{" "}
            {row.full_name} {row.phone} không ?
          </Typography>
        </Box>
      ),
      onPass: () => {
        setLoading(true);
        app
          .post(
            `${configs.customer}domain-resend/${row._id}?family_id=${row.family._id}`
          )
          .then((data: any) => {
            setLoading(false);
            if (data.data) {
              setInitPopup({
                ...initPopup,
                openFlag: true,
                type: "success",
                content: "Bạn đã gửi mail đăng ký tên miền thành công!",
                onPass: () => {
                  setOpen(false);
                  getData();
                },
              });
            } else {
              setInitPopup({
                ...initPopup,
                openFlag: true,
                type: "fail",
                content: "Có lỗi xảy ra",
                onPass: () => {
                  setOpen(false);
                  getData();
                },
              });
            }
          })
          .catch((err) => {
            handleError(err);
          });
      },
    });
  };

  function PackageChange(props: { row: Customer; extend_check: boolean }) {
    const { row, extend_check } = props;
    const start_day = moment(new Date());
    const start_pack_day = moment(row.family.package_start_date);
    const end_day = moment(row.family.package_expiration_date);
    const left_day = end_day.diff(start_day, "days", true);
    const use_day = start_day.diff(start_pack_day, "days", true);
    const initialValidate = { invalid: false, errors: "" };
    const [validateprice, setvalidatePrice] = useState(initialValidate);
    // const [price, setPrice] = useState(
    //   Math.ceil(
    //     ((list_package.filter((x) => x._id != row.family.package_id)[0]
    //       .cycle_day -
    //       left_day) *
    //       list_package.filter((x) => x._id != row.family.package_id)[0].price) /
    //       list_package.filter((x) => x._id != row.family.package_id)[0]
    //         .cycle_day
    //   )
    // );
    let init_price = 0;
    let priceperday = 0;
    if (left_day >= 0) {
      priceperday =
        list_package.filter((x) => x._id == row.family.package_id)[0].price /
        list_package.filter((x) => x._id == row.family.package_id)[0].cycle_day;
      const cur_pack = list_package.filter(
        (x) => x._id == row.family.package_id
      )[0].price;
      const new_pack = list_package.filter(
        (x) => x._id != row.family.package_id
      )[0].price;

      init_price = new_pack - cur_pack + priceperday * use_day;
      init_price = init_price > 0 ? Math.ceil(init_price) : 0;
    } else {
      init_price = list_package.filter((x) => x._id != row.family.package_id)[0]
        .price;
    }
    const [price, setPrice] = useState(init_price);
    const initState = {
      new_package: list_package.filter(
        (x) => x._id != row.family.package_id
      )[0]!._id,
      note: "",
    };
    const [state, setState] = useState(initState);
    const [validatePercent, setvalidatePercent] = useState(initialValidate);
    const [sale_percent_value, setSalePercent] = useState(0);

    const Invalid = (e: any) => {
      if (
        Number((e.target as HTMLInputElement).value) < 1 &&
        list_package!.find((x) => x._id == state.new_package)!.price != 0
      ) {
        setvalidatePrice({
          errors: "Vui lòng nhập số tiền lớn hơn 1 đ",
          invalid: true,
        });
        (e.target as HTMLInputElement).setCustomValidity(" ");
      } else {
        setvalidatePrice(initialValidate);
        (e.target as HTMLInputElement).setCustomValidity("");
      }
    };

    const onBlurPrice = (e: any) => {
      if (
        Number((e.target as HTMLInputElement).value) < 1 &&
        list_package!.find((x) => x._id == state.new_package)!.price != 0
      ) {
        setvalidatePrice({
          errors: "Vui lòng nhập số tiền lớn hơn 1 đ",
          invalid: true,
        });
      } else {
        setvalidatePrice(initialValidate);
      }
    };

    const InvalidSale = (e: any) => {
      console.log(!(e.target as HTMLInputElement).value);
      if (
        Number((e.target as HTMLInputElement).value) < 0 ||
        Number((e.target as HTMLInputElement).value) > 100 ||
        !(e.target as HTMLInputElement).value
      ) {
        setvalidatePercent({
          errors: "Vui lòng nhập chiết khấu từ 0 - 100%.",
          invalid: true,
        });
        (e.target as HTMLInputElement).setCustomValidity(" ");
      } else {
        setvalidatePercent(initialValidate);
        (e.target as HTMLInputElement).setCustomValidity("");
      }
    };

    const onBlurPercentChange = (e: any) => {
      if (!(e.target as HTMLInputElement).value) {
        setvalidatePercent({
          errors: "Vui lòng nhập chiết khấu từ 0 - 100%.",
          invalid: true,
        });
      } else {
        setvalidatePercent(initialValidate);
      }
    };

    const onInput = (e: any) => {
      if (Number((e.target as HTMLInputElement).value) < 0) {
        (e.target as HTMLInputElement).value = "0";
        (e.target as HTMLInputElement).setCustomValidity(" ");
      } else if (Number((e.target as HTMLInputElement).value) > 100) {
        (e.target as HTMLInputElement).value = "100";
        (e.target as HTMLInputElement).setCustomValidity(" ");
      } else {
        (e.target as HTMLInputElement).setCustomValidity("");
        setvalidatePercent(initialValidate);
      }
    };

    const handleSalePercentChange = (event: any) => {
      const value = event.target.value;
      setSalePercent(value);
    };

    const handlePriceChange = (event: any) => {
      const value = event.target.value;
      setPrice(value);
    };
    const handleChange = (event: any) => {
      const name = event.target.name;
      const value = event.target.value;
      setState({ ...state, [name]: value });
    };
    const update_handler = () => {
      let status = undefined;
      if (
        (document.querySelector(".MuiCheckbox-input") as HTMLInputElement) !=
        null
      ) {
        status = (document.querySelector(
          ".MuiCheckbox-input"
        ) as HTMLInputElement)!.checked
          ? "COMPLETED"
          : "PENDING";
      }
      if (((100 - sale_percent_value) * price) / 100 == 0) {
        status = "COMPLETED";
      }

      app
        .post(configs.customer + "update_package", {
          customer_id: row._id,
          family_name: row.family.family_name,
          full_name: row.full_name,
          birth_day: row.birth_day,
          gender: row.gender == 1 ? "Nam" : row.gender == 2 ? "Nữ" : "",
          package_name: list_package.find((x) => x._id == state.new_package)!
            .package_name,
          family_id: row.family._id,
          status: status,
          email: row.email,
          price: ((100 - sale_percent_value) * price) / 100,
          total_money_before_sale: Number(price),
          phone: row.phone,
          sale_percent_value: sale_percent_value,
          package_id: state.new_package,
          prev_package_id: row.family.package_id,
          cycleday: list_package.find((x) => x._id == state.new_package)!
            .cycle_day,
          note: state.note,
          type:
            state.new_package == row.family.package_id
              ? "TYPE00003"
              : undefined,
        })
        .then((data) => {
          setLoading(false);
          if (data.data) {
            setInitPopup({
              ...initPopup,
              openFlag: true,
              type: "success",
              content:
                data.data == true
                  ? "Tạo hóa đơn thay đổi thành công! Vui lòng hoàn thành hóa đơn để nâng cấp gói."
                  : "Cập nhật gói dịch vụ thành công",
              onPass: () => {
                setOpen(false);
                setLoading(false);
                getData();
                setOpen(true);
              },
              onCancel: () => {
                setOpen(false);
                getData();
                setOpen(true);
                setLoading(false);
              },
            });
          }
        })
        .catch((err) => handleError(err));
    };
    const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (
        ((100 - sale_percent_value) * price) / 100 == 0 &&
        row.family.package_expiration_date &&
        new Date(row.family.package_expiration_date) < new Date()
      ) {
        setInitPopup({
          ...initPopup,
          type: "fail",
          openFlag: true,
          content: `Gói dịch vụ hiện tại đã hết hạn sử dụng. Vui lòng gia hạn trước khi thay đổi gói dịch vụ mới.`,
          onPass: () => {},
        });
      } else {
        setInitPopup({
          ...initPopup,
          type: "confirmed",
          openFlag: true,
          content: (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                level="body-lg"
                component={"p"}
                sx={{ marginBottom: "10px" }}
              >
                Bạn có muốn đổi gói dịch vụ cho dòng họ này không ?
              </Typography>
              {((100 - sale_percent_value) * price) / 100 > 0 ? (
                <Checkbox size="md" label="Tự động hoàn thành đơn hàng" />
              ) : (
                <></>
              )}
            </Box>
          ),
          onPass: () => {
            setLoading(true);
            update_handler();
          },
        });
      }
    };
    return (
      <form onSubmit={onSubmitHandler}>
        <Typography component="h3" id="basic-modal-dialog-title">
          Đổi gói dịch vụ chính
        </Typography>
        <Typography level="h4" id="basic-modal-dialog-description">
          Dòng họ : {row.family.family_name}-
          {row.family.family_alias_name +
            row.family.family_alias_number.toString().padStart(4, "0")}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ width: "100%" }} required>
            <FormLabel>Gói dịch vụ hiện tại</FormLabel>
            <Input
              name="price"
              className="InputForm"
              value={
                row.product_package.find((x) => x._id == row.family.package_id)!
                  .package_name
              }
              slotProps={{
                input: {
                  readOnly: true,
                },
              }}
              sx={{ width: "100%" }}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }} required>
            <FormLabel>Giá dịch vụ hiện tại</FormLabel>
            <Input
              name="price"
              className="InputForm"
              endDecorator={"VND"}
              value={
                row.product_package.find((x) => x._id == row.family.package_id)!
                  .price
              }
              slotProps={{
                input: {
                  component: NumericFormatCustom as any,
                  readOnly: true,
                },
              }}
              sx={{ width: "100%" }}
            />
          </FormControl>
          <FormControl sx={{ margin: "3px", width: "100%" }} required>
            <FormLabel>Gói dịch vụ mới</FormLabel>
            <Select
              name="package"
              size="sm"
              value={state.new_package}
              sx={{ minWidth: 200 }}
              onChange={(e: any, newValue) => {
                setState({
                  ...state,
                  new_package: newValue || initState.new_package,
                });
                const found_package = list_package.find(
                  (x) => x._id == newValue
                );
                if (found_package) {
                  // setPrice(
                  //   Math.ceil(
                  //     ((found_package.cycle_day - left_day) * found_package.price) / found_package.cycle_day
                  //   )
                  // );

                  setPrice(
                    left_day >= 0
                      ? found_package.price -
                          list_package.filter(
                            (x) => x._id == row.family.package_id
                          )[0].price >
                        0
                        ? Math.ceil(
                            found_package.price -
                              list_package.filter(
                                (x) => x._id == row.family.package_id
                              )[0].price +
                              priceperday * use_day
                          )
                        : 0
                      : found_package.price
                  );
                } else setPrice(0);
              }}
            >
              {list_package.map((x) => {
                if (x._id == row.family.package_id) {
                  return;
                }
                return (
                  <Option key={`${x._id}`} value={x._id}>
                    {x.package_name}-
                    {x.price
                      .toLocaleString("vi-VN", {
                        style: "currency",
                        currency: "VND",
                      })
                      .replace("₫", "VND")}
                  </Option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "100%" }} required>
            <FormLabel>Số tiền chênh lệch</FormLabel>
            <Input
              name="price"
              className="InputForm"
              value={price}
              error={validateprice.invalid}
              onInvalid={Invalid}
              onBlur={onBlurPrice}
              endDecorator={"VND"}
              slotProps={{
                input: {
                  component: NumericFormatCustom as any,
                },
              }}
              onChange={handlePriceChange}
              sx={{ width: "100%" }}
            />
            {validateprice.invalid ? (
              <FormHelperText sx={{ color: "red" }}>
                {validateprice.errors}
              </FormHelperText>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl sx={{ width: "100%" }} required>
            <FormLabel>Chiết khấu</FormLabel>
            <Input
              name="sale_percent_value"
              className="InputForm"
              type="number"
              value={sale_percent_value}
              error={validatePercent.invalid}
              onInvalid={InvalidSale}
              onBlur={onBlurPercentChange}
              onInput={onInput}
              endDecorator={"%"}
              slotProps={{
                input: {
                  max: 100,
                  min: 0,
                },
              }}
              onChange={handleSalePercentChange}
              sx={{ width: "100%" }}
            />
            {validatePercent.invalid ? (
              <FormHelperText sx={{ color: "red" }}>
                {validatePercent.errors}
              </FormHelperText>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl sx={{ marginBottom: "10px", width: "100%" }}>
            <FormLabel>Ghi chú:</FormLabel>
            <Textarea
              name="note"
              value={state.note}
              minRows={4}
              maxRows={4}
              onChange={handleChange}
            />
          </FormControl>
          <Stack
            spacing={1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* <Typography level="h4">Tổng cổng: {(100 - sale_percent_value)*price/100} VNĐ</Typography> */}
            <Table borderAxis="none">
              <tfoot>
                <tr>
                  <th scope="row" style={{ color: "black" }}>
                    Tổng cộng
                  </th>
                  <td>
                    <b>
                      {(((100 - sale_percent_value) * price) / 100)
                        .toLocaleString("vi-VN", {
                          style: "currency",
                          currency: "VND",
                        })
                        .replace("₫", "VND")}
                    </b>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Stack>
          <Button sx={{ marginTop: "10px" }} type="submit">
            Đổi gói dịch vụ
          </Button>
        </div>
      </form>
    );
  }

  function PackageExtend(props: { row: Customer }) {
    const { row } = props;
    const initialValidate = { invalid: false, errors: "" };
    const [validateprice, setvalidatePrice] = useState(initialValidate);

    const [quantity, setQuantity] = useState(1);
    const initState = {
      new_package: list_extpackage.filter(
        (x) => x.price > 0 && x._id != row.family.package_id
      )[0]._id,
      note: "",
    };
    const [state, setState] = useState(initState);
    const [validatePercent, setvalidatePercent] = useState(initialValidate);
    const [sale_percent_value, setSalePercent] = useState(0);
    // const [priceperday, setPriceperDay] = useState(0);
    // const [left_day, setLeftDay] = useState(0);
    const chosen_package = list_extpackage.find(
      (x) => x._id == state.new_package
    );
    // setPriceperDay(10000)
    const priceperday = chosen_package!.price / chosen_package!.cycle_day;
    const start_day = moment(new Date());
    const end_day = moment(row.family.package_expiration_date);
    // setLeftDay(111)
    const left_day = end_day.diff(start_day, "days");

    const Invalid = (e: any) => {
      if (Number((e.target as HTMLInputElement).value) < 1) {
        setvalidatePrice({
          errors: "Vui lòng nhập số lượng lớn hơn 1",
          invalid: true,
        });
        (e.target as HTMLInputElement).setCustomValidity(" ");
      } else {
        setvalidatePrice(initialValidate);
        (e.target as HTMLInputElement).setCustomValidity("");
      }
    };

    const InvalidSale = (e: any) => {
      if (
        Number((e.target as HTMLInputElement).value) < 0 ||
        Number((e.target as HTMLInputElement).value) > 100 ||
        (e.target as HTMLInputElement).value.length <= 0
      ) {
        setvalidatePercent({
          errors: "Vui lòng nhập chiết khấu từ 0 - 100%.",
          invalid: true,
        });
        (e.target as HTMLInputElement).setCustomValidity(" ");
      } else {
        setvalidatePercent(initialValidate);
        (e.target as HTMLInputElement).setCustomValidity("");
      }
    };

    const onBlurPercentChange = (e: any) => {
      if (!(e.target as HTMLInputElement).value) {
        setvalidatePercent({
          errors: "Vui lòng nhập chiết khấu từ 0 - 100%.",
          invalid: true,
        });
      } else {
        setvalidatePercent(initialValidate);
      }
    };

    const onInputSale = (e: any) => {
      if (Number((e.target as HTMLInputElement).value) < 0) {
        (e.target as HTMLInputElement).value = "0";
        (e.target as HTMLInputElement).setCustomValidity(" ");
      } else if (
        Number((e.target as HTMLInputElement).value) > 100 &&
        (e.target as HTMLInputElement).name == "sale_percent_value"
      ) {
        (e.target as HTMLInputElement).value = "100";
        (e.target as HTMLInputElement).setCustomValidity(" ");
      } else if (
        Number((e.target as HTMLInputElement).value) > 10000 &&
        (e.target as HTMLInputElement).name == "count"
      ) {
        (e.target as HTMLInputElement).value = "10000";
        (e.target as HTMLInputElement).setCustomValidity(" ");
      } else {
        (e.target as HTMLInputElement).setCustomValidity("");
        setvalidatePercent(initialValidate);
      }
    };

    const handleSalePercentChange = (event: any) => {
      const value = event.target.value;
      setSalePercent(value);
    };

    const onBlurPrice = (e: any) => {
      if (!(e.target as HTMLInputElement).value) {
        setvalidatePrice({
          errors: "Vui lòng nhập số lượng lớn hơn 1",
          invalid: true,
        });
      } else {
        setvalidatePrice(initialValidate);
      }
    };

    const handlePriceChange = (event: any) => {
      const value = event.target.value;
      setQuantity(value);
    };
    const handleChange = (event: any) => {
      const name = event.target.name;
      const value = event.target.value;
      setState({ ...state, [name]: value });
    };
    const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
      setLoading(true);
      event.preventDefault();
      await app
        .post(configs.customer + "update_package_extend", {
          customer_id: row._id,
          family_id: row.family._id,
          family_name: row.family.family_name,
          full_name: row.full_name,
          birth_day: row.birth_day,
          gender: row.gender == 1 ? "Nam" : row.gender == 2 ? "Nữ" : "",
          package_name: list_extpackage.find((x) => x._id == state.new_package)!
            .package_name,
          email: row.email,
          count: Number(quantity),
          phone: row.phone,
          sale_percent_value,
          price:
            (Number(quantity) *
              (100 - sale_percent_value) *
              left_day *
              priceperday) /
            100,
          total_money_before_sale: Number(quantity) * left_day * priceperday,
          cycle_day: list_extpackage.find((x) => x._id == state.new_package)!
            .cycle_day,
          package_id: state.new_package,
          note: state.note,
          type: "TYPE00001",
        })
        .then((data) => {
          setLoading(false);
          if (data.data) {
            setInitPopup({
              ...initPopup,
              openFlag: true,
              type: "success",
              content: "Cập nhật gói dịch vụ thành công",
              onPass: () => {
                setOpen(true);
                getData();
              },
              onCancel: () => {
                setOpen(true);
                getData();
              },
            });
          }
        })
        .catch((err) => handleError(err));
    };

    return (
      <form onSubmit={onSubmitHandler}>
        <Typography component="h3" id="basic-modal-dialog-title">
          Thêm gói dịch vụ phụ
        </Typography>
        <Typography level="h4" id="basic-modal-dialog-description">
          Dòng họ : {row.family.family_name}-
          {row.family.family_alias_name +
            row.family.family_alias_number.toString().padStart(4, "0")}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ margin: "3px", width: "100%" }} required>
            <FormLabel>Gói dịch vụ mới</FormLabel>
            <Select
              name="package"
              size="sm"
              value={state.new_package}
              sx={{ minWidth: 200 }}
              onChange={(e: any, newValue) => {
                // const chosen_package = list_extpackage.find(x=>x._id==newValue);
                // console.log(chosen_package);
                // console.log(newValue);
                // setPriceperDay(10000)
                // // const priceperday = chosen_package!.price/chosen_package!.cycle_day;
                // const start_day = moment(new Date);
                // const end_day = moment(row.family.package_expiration_date);
                // setLeftDay(111)
                // // const left_day = end_day.diff(start_day,'days',true);
                setState({
                  ...state,
                  new_package: newValue || initState.new_package,
                });
              }}
            >
              {list_extpackage.map((x) => {
                if (x.price <= 0) {
                  return;
                }
                if (x._id == row.family.package_id) {
                  return;
                }
                return (
                  <Option key={`${x._id}`} value={x._id}>
                    {x.package_name}-
                    {x.price
                      .toLocaleString("vi-VN", {
                        style: "currency",
                        currency: "VND",
                      })
                      .replace("₫", "VND")}
                  </Option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "100%" }} required>
            <FormLabel>Số lượng mua</FormLabel>
            <Input
              name="count"
              className="InputForm"
              type="number"
              value={quantity}
              error={validateprice.invalid}
              onInvalid={Invalid}
              onBlur={onBlurPrice}
              onKeyDown={(e) => {
                if (e.key == ".") {
                  e.preventDefault();
                }
              }}
              onInput={onInputSale}
              slotProps={{
                input: {
                  min: 1,
                  maxLength: 10,
                },
              }}
              onChange={handlePriceChange}
              sx={{ width: "100%" }}
            />
            {validateprice.invalid ? (
              <FormHelperText sx={{ color: "red" }}>
                {validateprice.errors}
              </FormHelperText>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl sx={{ width: "100%" }} required>
            <FormLabel>Chiết khấu</FormLabel>
            <Input
              name="sale_percent_value"
              className="InputForm"
              type="number"
              value={sale_percent_value}
              error={validatePercent.invalid}
              onInvalid={InvalidSale}
              onBlur={onBlurPercentChange}
              onInput={onInputSale}
              endDecorator={"%"}
              slotProps={{
                input: {
                  max: 100,
                  min: 0,
                },
              }}
              onChange={handleSalePercentChange}
              sx={{ width: "100%" }}
            />
            {validatePercent.invalid ? (
              <FormHelperText sx={{ color: "red" }}>
                {validatePercent.errors}
              </FormHelperText>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl sx={{ marginBottom: "10px", width: "100%" }}>
            <FormLabel>Ghi chú:</FormLabel>
            <Textarea
              name="note"
              value={state.note}
              minRows={4}
              maxRows={4}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl sx={{ width: "98%" }}>
            <FormLabel>Số lượng thành viên</FormLabel>
            <Card
              variant="outlined"
              sx={{
                display: "grid",
                width: { xs: "90%", md: "92%", lg: "95%" },
                gridTemplateColumns: "30% 10% 20% 10% 30%",
                "& p": {
                  textAlign: "left",
                },
              }}
            >
              <Typography>
                {list_extpackage.find((x) => x._id == state.new_package)
                  ? list_extpackage.find((x) => x._id == state.new_package)!
                      .package_limit!.limit_member
                  : 0}
              </Typography>
              <Typography>x</Typography>
              <Typography>{quantity}</Typography>
              <Typography>=</Typography>
              <Typography>
                {(list_extpackage.find((x) => x._id == state.new_package)
                  ? list_extpackage.find((x) => x._id == state.new_package)!
                      .package_limit!.limit_member
                  : 0) * quantity}
              </Typography>
            </Card>
          </FormControl>
          <FormControl sx={{ width: "98%" }}>
            <FormLabel>Dung lượng (MB)</FormLabel>
            <Card
              variant="outlined"
              sx={{
                display: "grid",
                width: { xs: "90%", md: "92%", lg: "95%" },
                gridTemplateColumns: "30% 10% 20% 10% 30%",
                "& p": {
                  textAlign: "left",
                },
              }}
            >
              <Typography>
                {list_extpackage.find((x) => x._id == state.new_package)
                  ? list_extpackage.find((x) => x._id == state.new_package)!
                      .package_limit!.limit_storage_mb
                  : 0}
              </Typography>
              <Typography>x</Typography>
              <Typography>{quantity}</Typography>
              <Typography>=</Typography>
              <Typography>
                {(list_extpackage.find((x) => x._id == state.new_package)
                  ? Number(
                      list_extpackage.find((x) => x._id == state.new_package)!
                        .package_limit!.limit_storage_mb
                    )
                  : 0) * quantity}
              </Typography>
            </Card>
          </FormControl>

          <FormControl sx={{ width: "98%", marginBottom: "10px" }}>
            <FormLabel>Tổng tiền (VND)</FormLabel>
            <Card
              variant="outlined"
              sx={{
                display: "grid",
                width: { xs: "90%", md: "93%", lg: "95%" },
                gridTemplateColumns: "30% 10% 20% 10% 30%",
                "& p": {
                  textAlign: "center",
                },
              }}
            >
              <Typography level="body-lg" component="h6">
                {list_extpackage.find((x) => x._id == state.new_package)
                  ? (
                      ((100 - sale_percent_value) * left_day * priceperday) /
                      100
                    )
                      .toLocaleString("vi-VN", {
                        // style: "currency",
                        // currency: "VND",
                        notation: "compact",
                        compactDisplay: "long",
                      })
                      .replace("₫", "")
                  : 0}
              </Typography>
              <Typography level="body-lg" component="h6">
                x
              </Typography>
              <Typography level="body-lg" component="h6">
                {quantity}
              </Typography>
              <Typography level="body-lg" component="h6">
                =
              </Typography>
              <Typography level="body-lg" component="h6">
                {(
                  (list_extpackage.find((x) => x._id == state.new_package)
                    ? ((100 - sale_percent_value) * left_day * priceperday) /
                      100
                    : 0) * quantity
                )
                  .toLocaleString("vi-VN", {
                    // style: "currency",
                    // currency: "VND",
                    notation: "compact",
                    compactDisplay: "long",
                  })
                  .replace("₫", "")}
              </Typography>
            </Card>
          </FormControl>
          <Button type="submit">Thêm gói dịch vụ</Button>
        </div>
      </form>
    );
  }

  function PeriodExtend(props: {
    row: Customer;
    package_id: string;
    extend_flag: boolean;
    arr?: any;
  }) {
    const { row, package_id, extend_flag, arr } = props;
    const package_infor = row.product_package.find((x) => x._id == package_id);
    let total_price_package = 0;

    total_price_package += Number(package_infor!.price);
    const package_extend: any = row.family.package_extend;

    package_extend.map((item: any) => {
      const findPackageExt = row.product_package.find(
        (e) => e._id.toString() == item.package_id.toString()
      );
      if (findPackageExt) {
        item.package_type = findPackageExt.package_type;
        item.package_name = findPackageExt.package_name;
        item.price = findPackageExt.price;
        total_price_package +=
          Number(findPackageExt.price) * Number(item.count);
      }
    });

    let count = 1;
    let cycle_day = package_infor!.cycle_day;
    if (arr) {
      count = arr!.count ? arr!.count : 1;
      const start_day = moment(new Date());
      const end_day = moment(row.family.package_expiration_date);
      cycle_day = end_day.diff(start_day, "days");
    }
    const [reason, setReason] = useState("");
    const [sale_percent, setSalepercent] = useState<string | undefined>("0");
    const [editTime, setEditTime] = useState(false);
    const [start_date, setStartDate] = useState<Date | undefined>(new Date());

    const updateExpireDate = () => {
      if (sale_percent == "") {
        setSalepercent("0");
      }
      setEditTime(false);
    };

    const Invalid = (e: any) => {
      // if (Number((e.target as HTMLInputElement).value) < 0 || Number((e.target as HTMLInputElement).value) > 100) {
      //   setvalidatePercent({
      //     errors: "Vui lòng nhập chiết khấu từ 0 - 100%.",
      //     invalid: true,
      //   });
      //   (e.target as HTMLInputElement).setCustomValidity(" ");
      // } else {
      //   setvalidatePercent(initialValidate);
      //   (e.target as HTMLInputElement).setCustomValidity("");
      // }
    };

    const onBlurPercentChange = (e: any) => {
      // if (Number((e.target as HTMLInputElement).value) < 0) {
      //   setvalidatePercent({
      //     errors: "Vui lòng nhập chiết khấu từ 0 - 100%.",
      //     invalid: true,
      //   });
      // } else {
      //   setvalidatePercent(initialValidate);
      // }
    };

    const onInput = (e: any) => {
      if (Number((e.target as HTMLInputElement).value) < 0) {
        (e.target as HTMLInputElement).value = "0";
        (e.target as HTMLInputElement).setCustomValidity(" ");
      } else if (Number((e.target as HTMLInputElement).value) > 100) {
        (e.target as HTMLInputElement).value = "100";
        (e.target as HTMLInputElement).setCustomValidity(" ");
      } else {
        (e.target as HTMLInputElement).setCustomValidity("");
      }
    };

    const handleSalePercentChange = (event: any) => {
      const value = event.target.value;
      setSalepercent(value);
    };
    const handleChange = (e: any) => {
      setReason(e.target.value);
    };
    const handleSubmit = (event: any) => {
      event.preventDefault();
      setInitPopup({
        ...initPopup,
        type: "confirmed",
        openFlag: true,
        content: "Bạn có muốn gia hạn gói dịch vụ cho dòng họ này không ?",
        onPass: () => {
          setLoading(true);
          if (extend_flag) {
            app
              .post(configs.customer + "update_package_extend", {
                customer_id: row._id,
                family_id: row.family._id,
                family_name: row.family.family_name,
                full_name: row.full_name,
                birth_day: row.birth_day,
                gender: row.gender == 1 ? "Nam" : row.gender == 2 ? "Nữ" : "",
                package_name: list_extpackage!.find(
                  (x) => x._id == arr.package_id
                )!.package_name,
                email: row.email,
                count: arr.count,
                package_id: arr.package_id,
                phone: row.phone,
                cycle_day: list_extpackage.find((x) => x._id == arr.package_id)!
                  .cycle_day,
                extend_id: arr.extend_id,
                type: "TYPE00003",
              })
              .then((data) => {
                setLoading(false);
                if (data.data) {
                  setInitPopup({
                    ...initPopup,
                    openFlag: true,
                    type: "success",
                    content: "Gia hạn gói phụ dịch vụ thành công",
                    onPass: () => {
                      getData();
                      setOpen(true);
                    },
                    onCancel: () => {
                      getData();
                      setOpen(true);
                    },
                  });
                }
              })
              .catch((err) => handleError(err));
          } else {
            app
              .post(configs.customer + "update_package_all", {
                customer_id: row._id,
                family_id: row.family._id,
                family_name: row.family.family_name,
                full_name: row.full_name,
                birth_day: row.birth_day,
                gender: row.gender == 1 ? "Nam" : row.gender == 2 ? "Nữ" : "",
                package_name: list_package.find(
                  (x) => x._id == row.family.package_id
                )!.package_name,
                email: row.email,
                price: Number(
                  list_package.find((x) => x._id == row.family.package_id)!
                    .price
                ),
                package_extend: row.family.package_extend,
                phone: row.phone,
                package_id: row.family.package_id,
                prev_package_id: row.family.package_id,
                cycleday: list_package.find(
                  (x) => x._id == row.family.package_id
                )!.cycle_day,
                note: reason,
                type: "TYPE00003",
                status: "COMPLETED",
                sale_percent_value: sale_percent,
                start_date: start_date,
              })
              .then((data) => {
                setLoading(false);
                if (data.data) {
                  setInitPopup({
                    ...initPopup,
                    openFlag: true,
                    type: "success",
                    content: "Gia hạn gói dịch vụ thành công",
                    onPass: () => {
                      getData();
                      setOpen(true);
                    },
                  });
                }
              })
              .catch((err) => handleError(err));
          }
        },
      });
    };

    return (
      <Box minWidth={400}>
        <Typography component="h3" id="basic-modal-dialog-title">
          Xác nhận gia hạn gói dịch vụ
        </Typography>
        <Divider />
        <form onSubmit={(event) => handleSubmit(event)}>
          <Table borderAxis="none" className="updatePackage">
            <tbody>
              <tr>
                <td colSpan={2}>
                  <Box
                    sx={{
                      // height: { xl: "62vh", sm: "60vh" },
                      overflow: "auto",
                    }}
                  >
                    <Table
                      borderAxis="none"
                      // sx={{ "--unstable_TableCell-height": "35px" }}
                    >
                      <tbody>
                        <tr>
                          <th scope="row" style={{ color: "black" }}>
                            Dòng họ:
                          </th>
                          <td>
                            {row.family.family_name +
                              "-" +
                              row.family.family_alias_name +
                              row.family.family_alias_number
                                .toString()
                                .padStart(4, "0")}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" style={{ color: "black" }}>
                            Họ và tên:
                          </th>
                          <td>{row.full_name}</td>
                        </tr>
                        <tr>
                          <th scope="row" style={{ color: "black" }}>
                            Email:
                          </th>
                          <td style={{ wordWrap: "break-word" }}>
                            {row.email}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" style={{ color: "black" }}>
                            Điện thoại:
                          </th>
                          <td>{row.phone}</td>
                        </tr>
                        <tr>
                          <th scope="row" style={{ color: "black" }}>
                            Ngày gia hạn:
                          </th>
                          <td>
                            <DatePicker
                              locale={vi}
                              openToDate={start_date}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="dd/mm/yyyy"
                              onChange={(date) =>
                                setStartDate(date ? date : undefined)
                              }
                              selected={start_date}
                              startDate={start_date}
                              customInput={
                                <Input
                                  sx={{ width: 160 }}
                                  size="sm"
                                  endDecorator={
                                    <CalendarTodaySharpIcon fontSize="inherit" />
                                  }
                                />
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" style={{ color: "black" }}>
                            Chu kì gia hạn:
                          </th>
                          <td>{cycle_day}</td>
                        </tr>
                        <tr>
                          <th scope="row" style={{ color: "black" }}>
                            Ngày hết hạn:
                          </th>
                          <td>
                            {moment(start_date)
                              .add(package_infor!.cycle_day, "days")
                              .utcOffset("+07")
                              .endOf("day")
                              .format("DD/MM/YYYY H:mm")}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <Stack spacing={3}>
                              <FormControl>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "7px 0",
                                  }}
                                >
                                  <FormLabel>Ghi chú:</FormLabel>
                                </Box>

                                <Textarea
                                  value={reason}
                                  minRows={3}
                                  onChange={handleChange}
                                />
                              </FormControl>
                            </Stack>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Box>
                </td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <th
                  scope="row"
                  style={{ color: "black", backgroundColor: "transparent" }}
                >
                  Thông tin dịch vụ:
                </th>
              </tr>
              <Table
                borderAxis="both"
                sx={{ width: "200%" }}
                className="ListService"
              >
                <thead>
                  <tr>
                    <th>Dịch vụ</th>
                    <th>Số lượng</th>
                    <th>Đơn giá</th>
                    <th>Thành tiền</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      {package_infor!.package_name}
                    </td>
                    <td>1</td>
                    <td style={{ textAlign: "end" }}>
                      {(package_infor!.price || 0)
                        .toLocaleString("vi-VN", {
                          style: "currency",
                          currency: "VND",
                        })
                        .replace("₫", "VND")}
                    </td>
                    <td style={{ textAlign: "end" }}>
                      {(package_infor!.price || 0)
                        .toLocaleString("vi-VN", {
                          style: "currency",
                          currency: "VND",
                        })
                        .replace("₫", "VND")}
                    </td>
                  </tr>
                  {package_extend.map((item: any, index: number) => (
                    <tr key={index}>
                      <td style={{ textAlign: "left" }}>{item.package_name}</td>
                      <td>{item.count}</td>
                      <td style={{ textAlign: "end" }}>
                        {(item.price || 0)
                          .toLocaleString("vi-VN", {
                            style: "currency",
                            currency: "VND",
                          })
                          .replace("₫", "VND")}
                      </td>
                      <td style={{ textAlign: "end" }}>
                        {(item.price * item.count || 0)
                          .toLocaleString("vi-VN", {
                            style: "currency",
                            currency: "VND",
                          })
                          .replace("₫", "VND")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <tr style={{ backgroundColor: "transparent" }}>
                <th scope="row" style={{ color: "black", marginTop: "10px" }}>
                  Chiết khấu:
                </th>
                <td>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {!editTime ? (
                      sale_percent + "%"
                    ) : (
                      <Input
                        className="InputForm"
                        name="birth_day"
                        value={sale_percent}
                        type="number"
                        size="sm"
                        sx={{ width: "100%" }}
                        onInvalid={Invalid}
                        onBlur={onBlurPercentChange}
                        onInput={onInput}
                        startDecorator={"%"}
                        slotProps={{
                          input: {
                            max: 100,
                            min: 0,
                          },
                        }}
                        onChange={handleSalePercentChange}
                        endDecorator={
                          <>
                            <ChipDelete
                              color="neutral"
                              variant="plain"
                              disabled={sale_percent != "" ? false : true}
                              onClick={() => {
                                setSalepercent("");
                              }}
                            >
                              <ClearIcon />
                            </ChipDelete>
                            <IconButton
                              color="success"
                              variant="plain"
                              sx={{
                                height: "var(--Chip-deleteSize, 2rem)",
                                width: "var(--Chip-deleteSize, 2rem)",
                                borderRadius: "var(--Chip-deleteRadius, 50%)",
                                margin: "var(--Chip-deleteMargin",
                              }}
                              onClick={updateExpireDate}
                            >
                              <CheckIcon />
                            </IconButton>
                          </>
                        }
                      />
                    )}
                    {!editTime ? (
                      <IconButton
                        variant="plain"
                        size="sm"
                        sx={{ marginLeft: "10px" }}
                        onClick={() => setEditTime(true)}
                      >
                        <ModeEditIcon color="info" />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row" style={{ color: "black" }}>
                  Tổng cộng
                </th>
                <td>
                  <b>
                    {(
                      (total_price_package * (100 - Number(sale_percent))) /
                      100
                    )
                      .toLocaleString("vi-VN", {
                        style: "currency",
                        currency: "VND",
                      })
                      .replace("₫", "VND")}
                  </b>
                </td>
              </tr>
            </tfoot>
          </Table>
          <Stack
            spacing={3}
            sx={{
              padding: "10px",
              margin: "auto",
              "& >:not(style):not(style)": { margin: "auto" },
            }}
          >
            <Button
              type="submit"
              sx={{
                width: "150px",
                margin: "auto",
              }}
            >
              Hoàn thành
            </Button>
          </Stack>
        </form>
      </Box>
    );
  }

  function InforRender(props: { row: Customer }) {
    const initrow = props.row;
    const [editTime, setEditTime] = useState(false);
    const [row, setRowChild] = useState(initrow);
    const [expiration_date, setExpirationDate] = useState<Date | null>(
      row.family.package_expiration_date != null
        ? new Date(row.family.package_expiration_date)
        : null
    );
    const [childopen, setChildOpen] = useState(false);
    const [childpopup, setChildPopUp] = useState<JSX.Element>(<></>);
    const package_extend_all = [
      ...(row.family.package_extend
        ? row.family.package_extend.map((item: any) => {
            return {
              ...item,
              isDelete: 0,
            };
          })
        : []),
      ...(row.family.package_extend_delete
        ? row.family.package_extend_delete.map((item: any) => {
            return {
              ...item,
              isDelete: 1,
            };
          })
        : []),
    ];

    package_extend_all.sort((a, b) => a.isDelete - b.isDelete);

    useEffect(() => {
      getDetail();
    }, [open]);
    const getDetail = () => {
      try {
        app
          .get(`${configs.customer}${row._id}?family_id=${row.family._id}`)
          .then((data: any) => {
            if (data.data) {
              setRowChild(data.data as Customer);

              setExpirationDate(
                (data.data as Customer).family.package_expiration_date != null
                  ? new Date(
                      (data.data as Customer).family.package_expiration_date
                    )
                  : null
              );
            }
          });
      } catch (err) {
        handleError(err);
      }
    };
    const changePackage = () => {
      // if (new Date(row.family.package_expiration_date) < new Date()) {
      //   setInitPopup({
      //     ...initPopup,
      //     type: "fail",
      //     openFlag: true,
      //     content: (
      //       <div
      //         dangerouslySetInnerHTML={{
      //           __html: `Gói dịch vụ hiện tại đã hết hạn sử dụng.<br />Vui lòng gia hạn trước khi thay đổi gói dịch vụ mới.`,
      //         }}
      //       />
      //     ),
      //     onPass: () => {},
      //   });
      // } else {
      //   setChildOpen(true);
      //   setChildPopUp(<PackageChange row={row} extend_check={expire_flag} />);
      // }
      setChildOpen(true);
      setChildPopUp(<PackageChange row={row} extend_check={expire_flag} />);
    };

    const addPackageExtend = () => {
      setChildOpen(true);
      setChildPopUp(<PackageExtend row={row} />);
    };

    const ChildPopupRender = ({ children }: any) => {
      return (
        <Modal open={childopen} onClose={() => setChildOpen(false)}>
          <ModalOverflow>
            <ModalDialog
              aria-labelledby="basic-modal-dialog-title"
              aria-describedby="basic-modal-dialog-description"
              sx={{ width: "35vw", minWidth: "400px" }}
              size="md"
            >
              <ModalClose />
              {children}
            </ModalDialog>
          </ModalOverflow>
        </Modal>
      );
    };

    const extendPeriod = () => {
      if (row.order_pending && row.order_pending.length) {
        const order_id =
          row.order_pending[0].order_alias_name &&
          row.order_pending[0].order_alias_number
            ? row.order_pending[0].order_alias_name +
              row.order_pending[0].order_alias_number
                .toString()
                .padStart(4, "0")
            : "";
        const type_name =
          row.order_pending[0].type == "TYPE00001"
            ? "Đăng ký gói"
            : row.order_pending[0].type == "TYPE00002"
            ? "Thay đổi gói"
            : row.order_pending[0].type == "TYPE00003"
            ? "Gia hạn"
            : "";
        setInitPopup({
          ...initPopup,
          type: "fail",
          openFlag: true,
          content: (
            <div
              dangerouslySetInnerHTML={{
                __html: `Bạn có một hóa đơn chờ xử lý. Vui lòng hoàn thành trước khi thực hiện gia hạn gói. <br />Mã hóa đơn : ${order_id} - Loại : ${type_name} `,
              }}
            />
          ),
          onPass: () => {},
        });
      } else {
        setChildOpen(true);
        setChildPopUp(
          <PeriodExtend
            row={row}
            package_id={row.family.package_id}
            extend_flag={false}
          />
        );
      }
    };

    const updatePeriodExtend = (arr: any) => {
      setChildOpen(true);
      setChildPopUp(
        <PeriodExtend
          row={row}
          package_id={arr.package_id}
          extend_flag={true}
          arr={arr}
        />
      );
    };
    const deletePeriodExtend = (arr: any) => {
      setInitPopup({
        ...initPopup,
        type: "confirmed",
        openFlag: true,
        content: "Bạn có chắc muốn hủy sử dụng dịch vụ này không ?",
        onPass: () => {
          setLoading(true);
          app
            .post(configs.customer + "cancel_use_package_extend", {
              family_id: row.family._id,
              package_id: arr.package_id,
              extend_id: arr.extend_id,
            })
            .then((data) => {
              setLoading(false);
              if (data.data) {
                setInitPopup({
                  ...initPopup,
                  openFlag: true,
                  type: "success",
                  content: "Hủy sử dụng dịch vụ thành công",
                  onPass: () => {
                    getData();
                    setOpen(true);
                  },
                  onCancel: () => {
                    getData();
                    setOpen(true);
                  },
                });
              }
            })
            .catch((err) => handleError(err));
        },
      });
    };

    const updateExpireDate = () => {
      if (
        expiration_date != null &&
        expiration_date!.getTime() !=
          new Date(row.family.package_expiration_date).getTime()
      ) {
        setLoading(true);
        app
          .post(configs.customer + "update_expire", {
            family_id: row.family._id,
            expiration_date: expiration_date,
          })
          .then((data) => {
            setLoading(false);
            if (data.data) {
              setInitPopup({
                ...initPopup,
                openFlag: true,
                type: "success",
                content: "Cập nhật ngày hết hạn thành công",
                onPass: () => {
                  getData();
                  setOpen(true);
                },
              });
            }
          })
          .catch((err) => handleError(err));
      } else {
        setEditTime(false);
        setExpirationDate(new Date(row.family.package_expiration_date));
      }
    };
    const expire_flag = moment().isAfter(row.family.package_expiration_date);
    return (
      <Box sx={{ minWidth: "400px" }}>
        <Typography component="h3" id="basic-modal-dialog-title">
          Thông tin gói dịch vụ
        </Typography>
        <Typography level="h4" id="basic-modal-dialog-description">
          Dòng họ : {row.family.family_name}-
          {row.family.family_alias_name +
            row.family.family_alias_number.toString().padStart(4, "0")}
        </Typography>
        <Typography level="body-lg" id="basic-modal-dialog-description">
          Email : {row.email}
        </Typography>
        <Typography level="body-lg" id="basic-modal-dialog-description">
          Số điện thoại :{row.phone}
        </Typography>
        <Tabs
          size="sm"
          aria-label="Pricing plan"
          defaultValue={0}
          sx={(theme) => ({
            width: { lg: "30vw", xs: "100%" },
            maxWidth: "1200px",
            minWidth: "600px",
            minHeight: "70vh",
            height: "52vh",
            "--Tabs-gap": "0px",
            borderRadius: "lg",
            // boxShadow: "sm",
            overflow: "auto",
            // border: `1px solid ${theme.vars.palette.divider}`,
            margin: "auto",
          })}
        >
          <TabList
            sx={{
              "--ListItem-radius": "0px",
              borderRadius: 0,
              [`& .${tabClasses.root}`]: {
                fontWeight: "lg",
                flex: 1,
                bgcolor: "background.body",
                position: "sticky",
                [`&.${tabClasses.selected}`]: {
                  color: "primary.500",
                },
                [`&.${tabClasses.selected}:before`]: {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  bottom: -1,
                  width: "100%",
                  height: 2,
                  bgcolor: "primary.400",
                },
                [`&.${tabClasses.focusVisible}`]: {
                  outlineOffset: "-3px",
                },
              },
            }}
          >
            <Tab sx={{ py: 1.5, textAlign: "center" }}>
              Dịch vụ đang sử dụng
            </Tab>
            <Tab>Lịch sử thay đổi</Tab>
          </TabList>
          <TabPanel value={0} sx={{ p: 2, pt: 3 }}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                  },
                }}
              >
                <Typography level="h4">Gói dịch vụ chính</Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: {
                      sm: "30vw",
                      md: "55%",
                    },
                    gap: 2,
                  }}
                >
                  <ButtonGroup
                    variant="outlined"
                    sx={{
                      py: "2px",
                    }}
                  >
                    <MButoon
                      // size="small"
                      sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                        borderRadius: "8px 0px 0px 8px",
                      }}
                      onClick={changePackage}
                    >
                      Đổi gói dịch vụ
                    </MButoon>
                    <Tooltip title="Gia hạn">
                      <MButoon
                        disabled={
                          !expire_flag ||
                          !list_package.some(
                            (x) =>
                              x._id == row.family.package_id && x.price != 0
                          )
                        }
                        sx={{
                          textTransform: "none",
                          fontWeight: "bold",
                          borderRadius: "0px 8px 8px 0px",
                        }}
                        onClick={extendPeriod}
                      >
                        <RestartAltIcon
                          fontSize="small"
                          color={
                            !expire_flag ||
                            !list_package.some(
                              (x) =>
                                x._id == row.family.package_id && x.price != 0
                            )
                              ? "disabled"
                              : "primary"
                          }
                        />
                      </MButoon>
                    </Tooltip>
                  </ButtonGroup>
                </Box>
              </Box>
              <Table
                borderAxis="xBetween"
                sx={{
                  "& th": {
                    height: "30px",
                  },
                  "& tr": {
                    height: "30px",
                  },
                }}
              >
                <tbody>
                  <tr>
                    <th scope="row" style={{ color: "black" }}>
                      Tên gói dịch vụ:
                    </th>
                    <td>
                      {
                        row.product_package.find(
                          (x) => x._id == row.family.package_id
                        )!.package_name
                      }
                    </td>
                    <td style={{ width: "50px" }}></td>
                  </tr>
                  <tr>
                    <th scope="row" style={{ color: "black" }}>
                      Ngày bắt đầu:
                    </th>
                    <td>{convertDate(row.family.package_start_date)}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row" style={{ color: "black" }}>
                      Ngày hết hạn:
                    </th>
                    <td>
                      {!editTime ? (
                        convertDate(expiration_date)
                      ) : (
                        <DatePicker
                          onChange={(date: any) =>
                            setExpirationDate(
                              moment(date ? date : undefined)
                                .endOf("day")
                                .toDate()
                            )
                          }
                          selected={expiration_date}
                          startDate={expiration_date}
                          minDate={new Date(row.family.package_start_date)}
                          customInput={
                            <Input
                              className="InputForm"
                              name="birth_day"
                              size="sm"
                              sx={{ width: "100%" }}
                              endDecorator={
                                <>
                                  <ChipDelete
                                    color="neutral"
                                    variant="plain"
                                    disabled={expiration_date ? false : true}
                                    onClick={() => {
                                      setExpirationDate(null);
                                    }}
                                  >
                                    <ClearIcon />
                                  </ChipDelete>
                                  <IconButton
                                    color="success"
                                    variant="plain"
                                    sx={{
                                      height: "var(--Chip-deleteSize, 2rem)",
                                      width: "var(--Chip-deleteSize, 2rem)",
                                      borderRadius:
                                        "var(--Chip-deleteRadius, 50%)",
                                      margin: "var(--Chip-deleteMargin",
                                    }}
                                    onClick={updateExpireDate}
                                  >
                                    <CheckIcon />
                                  </IconButton>
                                </>
                              }
                            />
                          }
                          locale={vi}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="dd/mm/yyyy"
                        />
                      )}
                    </td>
                    <td>
                      {!editTime ? (
                        <IconButton
                          variant="plain"
                          size="sm"
                          sx={{ marginLeft: "10px" }}
                          onClick={() => setEditTime(true)}
                        >
                          <EditCalendarOutlinedIcon color="info" />
                        </IconButton>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
              {list_extpackage.length >= 0 ? (
                list_package!.find((x) => x._id == row.family.package_id) ? (
                  list_package!.find((x) => x._id == row.family.package_id)!
                    .price <= 0 ? (
                    <></>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography level="h4">Gói dịch vụ phụ</Typography>
                        <Button
                          size="sm"
                          variant="outlined"
                          disabled={list_extpackage.length <= 0}
                          onClick={addPackageExtend}
                        >
                          Thêm gói dịch vụ phụ
                        </Button>
                      </div>
                      <Table
                        borderAxis="xBetween"
                        size={laptopUI ? "md" : "sm"}
                        sx={{
                          "& th": {
                            height: "20px",
                          },
                          "& tr": {
                            height: "30px",
                          },
                          "& tr>": {
                            height: "30px",
                          },
                        }}
                      >
                        <tbody>
                          <tr>
                            <th
                              style={{ wordWrap: "break-word", width: "30%" }}
                            >
                              Tên gói dịch vụ
                            </th>
                            <th
                              style={{ width: "10%", wordWrap: "break-word" }}
                            >
                              SL
                            </th>
                            <th style={{ textAlign: "center", width: "30%" }}>
                              Mã hóa đơn
                            </th>
                            {/* <th>Ngày bắt đầu</th> */}
                            <th style={{ width: "20%" }}>Ngày hết hạn</th>
                            <th style={{ width: "10%" }}></th>
                          </tr>
                          {package_extend_all ? (
                            package_extend_all.map((x, index) => {
                              const order_alias = row.order_extends
                                ? row.order_extends
                                    .filter((o) => o.ref_id == x.extend_id)
                                    .sort((a, b) => {
                                      return (
                                        new Date(b.create_at).getTime() -
                                        new Date(a.create_at).getTime()
                                      );
                                    })[0]
                                : undefined;

                              const expire_flags = moment().isAfter(
                                x.expiration_date
                              );
                              return (
                                <tr
                                  style={{
                                    backgroundColor:
                                      x.isDelete === 1 ? "#a3a3a8" : "inherit",
                                  }}
                                  key={index}
                                >
                                  <td>
                                    {row.product_package.find(
                                      (o) => o._id == x.package_id
                                    )
                                      ? row.product_package.find(
                                          (o) => o._id == x.package_id
                                        )!.package_name
                                      : ""}
                                  </td>
                                  <td>{x.count}</td>
                                  <td style={{ textAlign: "center" }}>
                                    {order_alias ? (
                                      <Link
                                        component="button"
                                        onClick={() => {
                                          window.open(
                                            "./order?searchName=" +
                                              order_alias!.order_alias_name +
                                              order_alias!.order_alias_number
                                                .toString()
                                                .padStart(4, "0"),
                                            "_blank",
                                            "noopener,noreferrer"
                                          );
                                        }}
                                      >
                                        {order_alias!.order_alias_name +
                                          order_alias!.order_alias_number
                                            .toString()
                                            .padStart(4, "0")}
                                      </Link>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                  {/* <td>{convertDate(x.extend_date)}</td> */}
                                  <td style={{ textAlign: "center" }}>
                                    <DisplayDate
                                      value={
                                        x.expiration_date
                                          ? x.expiration_date.toString()
                                          : ""
                                      }
                                    />
                                  </td>
                                  <td>
                                    {x.isDelete !== 1 && (
                                      <Tooltip
                                        title={
                                          "Hủy sử dụng gói " +
                                          (row.product_package.find(
                                            (o) => o._id == x.package_id
                                          )
                                            ? row.product_package.find(
                                                (o) => o._id == x.package_id
                                              )!.package_name
                                            : "")
                                        }
                                      >
                                        <IconButton
                                          variant="outlined"
                                          onClick={() => deletePeriodExtend(x)}
                                          disabled={!expire_flags}
                                        >
                                          <DeleteIcon
                                            color={
                                              !expire_flags
                                                ? "disabled"
                                                : "error"
                                            }
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </Table>
                    </>
                  )
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </Box>
          </TabPanel>
          <TabPanel value={1} sx={{ p: 1 }}>
            <Stepper nonLinear sx={{ overflow: "auto" }} orientation="vertical">
              {row.family.package_history!.map((step, index: number) => {
                let order_alias =
                  row.orders && step.order_id
                    ? row.orders.find((x) => x._id == step.order_id)
                    : undefined;
                let history_content;
                if (
                  !row.product_package!.find((x) => x._id == step.packge_id_new)
                ) {
                  return;
                }

                if (
                  !row.product_package!.find((x) => x._id == step.packge_id_old)
                ) {
                  return;
                }

                if (index == 0) {
                  history_content = "Đăng ký ";
                } else if (step.packge_id_new == step.packge_id_old) {
                  history_content = "Gia hạn ";
                } else {
                  history_content = "Chuyển sang ";
                }
                if (step.change_type == 1) {
                  history_content = (
                    <>
                      <b>
                        {
                          row.admin!.find((x) => x._id == step.update_user_id)!
                            .user_name
                        }
                      </b>{" "}
                      thay đổi {convertDate(step.change_date_old)} &rArr;{" "}
                      {convertDate(step.change_date_new)}
                    </>
                  );
                }

                return (
                  <Step key={step.history_id} completed>
                    <StepLabel>
                      <p>
                        {convertDate(
                          step.history_date ? step.history_date : row.create_at
                        )}
                      </p>
                      {order_alias != undefined ? (
                        <Typography level="body-lg">
                          <Link
                            component="button"
                            onClick={() => {
                              window.open(
                                "./order?searchName=" +
                                  order_alias!.order_alias_name +
                                  order_alias!.order_alias_number
                                    .toString()
                                    .padStart(4, "0"),
                                "_blank",
                                "noopener,noreferrer"
                              );
                            }}
                          >
                            {order_alias!.order_alias_name +
                              (order_alias.order_alias_number
                                ? order_alias.order_alias_number
                                    .toString()
                                    .padStart(4, "0")
                                : "0")}
                          </Link>
                        </Typography>
                      ) : (
                        ""
                      )}
                      <Typography level="body-md">
                        {history_content}
                        <b>
                          {step.change_type != 1 &&
                          row.product_package!.find(
                            (x) => x._id == step.packge_id_new
                          ) ? (
                            row.product_package!.find(
                              (x) => x._id == step.packge_id_new
                            )!.package_name! + ""
                          ) : (
                            <></>
                          )}
                        </b>
                      </Typography>
                    </StepLabel>
                    {/* <StepContent >
                    {step.packge_id_new == step.packge_id_old ? "Đăng kí " : "Chuyển sang "}
                        {row.product_package!.find(x=>x._id == step.packge_id_new)!.package_name}
                    </StepContent> */}
                  </Step>
                );
              })}
            </Stepper>
          </TabPanel>
        </Tabs>
        <ChildPopupRender>{childpopup}</ChildPopupRender>
      </Box>
    );
  }

  function GroupButton(props: { row: Customer; index: number }) {
    const { row } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <Dropdown>
        <ButtonGroup variant="text" size="small">
          <Tooltip title="Khôi phục mật khẩu" placement="left-end">
            <MButoon size="small" onClick={() => resetPwd(row)}>
              <LockResetIcon fontSize="small" />
            </MButoon>
          </Tooltip>

          <Tooltip title="Khôi phục dữ liệu" placement="left-end">
            <MButoon size="small" onClick={() => restoreDB(row)}>
              <RepartitionIcon fontSize="small" />
            </MButoon>
          </Tooltip>

          <Tooltip title="Thêm..." placement="left-end">
            <MenuButton
              size="sm"
              slots={{ root: IconButton }}
              slotProps={{ root: { variant: "plain", color: "primary" } }}
            >
              <MoreVertIcon fontSize="small" />
            </MenuButton>
          </Tooltip>
          <Menu
            id="positioned-demo-menu"
            aria-labelledby="positioned-demo-button"
            placement="bottom-end"
            size="md"
          >
            <MenuItem
              color="primary"
              onClick={() => {
                const first_domain = row.family.domain
                  .map((x, index) => {
                    const res = {
                      order: row.family.domain.length + 1,
                      key: x,
                    };
                    if (!configs.trial_domain.includes(x)) {
                      res["order"] = index;
                    }
                    return res;
                  })
                  .sort((a: any, b: any) => a!.order - b!.order)[0];
                window.open(first_domain.key, "_blank", "noopener,noreferrer");
              }}
            >
              <ListItemDecorator sx={{ color: "inherit" }}>
                <LinkOutlinedIcon color="primary" />
              </ListItemDecorator>
              Truy cập đường dẫn
            </MenuItem>
            <MenuItem
              color="primary"
              onClick={() => {
                setOpen(true);
                setPopUp(<InforRender row={row} />);
                setLayout(mobileUI);
              }}
            >
              <ListItemDecorator sx={{ color: "inherit" }}>
                <InfoOutlinedIcon />
              </ListItemDecorator>
              Thông tin gói dịch vụ
            </MenuItem>
            <MenuItem
              color="primary"
              onClick={() => navigate(`/customer/${row._id}/${row.family._id}`)}
            >
              <ListItemDecorator sx={{ color: "inherit" }}>
                <ModeEditIcon color="primary" />
              </ListItemDecorator>
              Sửa thông tin khách hàng
            </MenuItem>
            {(row.order_extends.length > 0 || row.orders.length > 0) && (
              <MenuItem color="primary" onClick={() => resendDomainkey(row)}>
                <ListItemDecorator sx={{ color: "inherit" }}>
                  <ForwardToInboxIcon color="primary" />
                </ListItemDecorator>
                Gửi link đăng ký tên miền
              </MenuItem>
            )}
            <MenuItem color="primary" onClick={() => deleteCustomer(row._id)}>
              <ListItemDecorator sx={{ color: "inherit" }}>
                <DeleteIcon />
              </ListItemDecorator>
              Xóa khách hàng
            </MenuItem>
          </Menu>
        </ButtonGroup>
      </Dropdown>
    );
  }

  function CustomSwitch(props: { row: Customer }) {
    const { row } = props;
    const [checked, setChecked] = useState(
      row.family.status == 0 ? false : true
    );
    const handleChange = async (event: any) => {
      if (row.family.status == 0) {
        setChecked(event.target.checked);
        setInitPopup({
          ...initPopup,
          type: "confirmed",
          openFlag: true,
          content: (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography>
                Bạn có muốn kích hoạt tài khoản cho khách hàng {row.full_name}{" "}
                {row.phone} không ?
              </Typography>
              {list_package.find((x) => x._id == row.family.package_id)!
                .price == 0 ? (
                <Checkbox
                  id="check"
                  label={
                    <Typography level="body-sm">
                      Hoặc gửi mã kích hoạt cho khách hàng.
                    </Typography>
                  }
                />
              ) : (
                <></>
              )}
            </Box>
          ),
          onPass: () => {
            const license_tag =
              Array.from(
                document.getElementsByClassName(
                  "MuiCheckbox-checkbox Mui-checked"
                )
              ).length > 0;
            setLoading(true);
            app
              .post(configs.customer + "update_status", {
                family_id: row.family._id,
                license_tag: license_tag,
              })
              .then((data: any) => {
                setLoading(false);
                if (data.data) {
                  setInitPopup({
                    ...initPopup,
                    openFlag: true,
                    type: "success",
                    content: "Bạn đã kích hoạt tài khoản thành công!",
                    onPass: () => {
                      setOpen(false);
                      getData();
                    },
                  });
                } else {
                  setInitPopup({
                    ...initPopup,
                    openFlag: true,
                    type: "fail",
                    content: "Có lỗi xảy ra",
                    onPass: () => {
                      setOpen(false);
                      getData();
                    },
                  });
                }
              })
              .catch((err) => {
                handleError(err);
              });
          },
        });
      }
    };
    return (
      <Switch
        checked={checked}
        size="sm"
        onChange={handleChange}
        sx={{
          "input[type=checkbox]:checked": {
            cursor: "default",
          },
        }}
      />
    );
  }

  return (
    <Box
      className="root_box"
      sx={{
        marginBottom: "auto",
        mx: "auto",
        width: { lg: "90%", xl: "84%" },
      }}
    >
      <div
        className="coverbox"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "flex-start",
          margin: "auto",
        }}
      >
        <Typography
          level="h4"
          component="h1"
          sx={{ alignSelf: "center", padding: "2vh" }}
        >
          Danh sách khách hàng
        </Typography>
        <Box
          className="RootBox"
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="FindBox" style={{ display: "flex" }}>
            <FormControl sx={{ margin: "3px", width: "100%" }} size="md">
              <FormLabel>
                Từ khóa tìm kiếm{" "}
                <Tooltip
                  title="KH đăng ký, Số điện thoại, Email, Mã định danh,..."
                  placement="right"
                  size="sm"
                >
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              </FormLabel>
              <Input
                placeholder="Nhập nội dung tìm kiếm"
                sx={{ minWidth: 200 }}
                size="sm"
                value={searchName}
                onChange={(e) => {
                  setName((e.target as HTMLInputElement).value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setName((e.target as HTMLInputElement).value);
                    setTempSearch({
                      ...tempSearch,
                      searchName: (e.target as HTMLInputElement).value,
                    });
                    setCurrentPage(1);
                    setsearchClick(!searchClick);
                  }
                }}
                // startDecorator={<SearchIcon />}
              />
            </FormControl>
            <Box sx={{ display: "flex", width: "90%" }}>
              <FormControl sx={{ margin: "3px", width: "100%" }} size="md">
                <FormLabel>Ngày đăng ký</FormLabel>
                <DatePicker
                  locale={vi}
                  openToDate={fromDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  onChange={(date) => setfromDate(date ? date : undefined)}
                  selected={fromDate}
                  startDate={fromDate}
                  customInput={
                    <Input
                      sx={{ width: 160 }}
                      size="sm"
                      // onChange={(e) => {
                      //   setfromDate((e.target as HTMLInputElement).value);
                      // }}
                      startDecorator={"Từ"}
                      endDecorator={
                        <CalendarTodaySharpIcon fontSize="inherit" />
                      }
                    />
                  }
                />
              </FormControl>
              <FormControl sx={{ margin: "3px", width: "50%" }} size="md">
                <div style={{ height: "25px" }}></div>
                <DatePicker
                  locale={vi}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  onChange={(date) => settoDate(date ? date : undefined)}
                  startDate={fromDate}
                  openToDate={new Date()}
                  endDate={toDate}
                  minDate={fromDate}
                  selected={toDate}
                  customInput={
                    <Input
                      sx={{ width: 160 }}
                      size="sm"
                      // onChange={(e) => {
                      //   setfromDate((e.target as HTMLInputElement).value);
                      // }}
                      startDecorator={"Đến"}
                      endDecorator={
                        <CalendarTodaySharpIcon fontSize="inherit" />
                      }
                    />
                  }
                />
              </FormControl>
            </Box>
            <FormControl sx={{ margin: "3px", width: "100%" }} size="md">
              <FormLabel>Gói dịch vụ</FormLabel>
              <Select
                name="package"
                size="sm"
                value={package_id}
                sx={{ minWidth: 200 }}
                onChange={(e: any, newValue) => {
                  setPackages(newValue || package_id);
                }}
              >
                <Option key="0" value="all">
                  Tất cả
                </Option>
                {list_package.map((x) => {
                  return (
                    <Option key={`${x._id}`} value={x._id}>
                      {x.package_name}
                    </Option>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ margin: "3px", width: "100%" }} size="md">
              <FormLabel>Trạng thái</FormLabel>
              <Select
                name="package"
                size="sm"
                value={status}
                sx={{ minWidth: 140 }}
                onChange={(e: any, newValue) => {
                  setStatus(newValue || status);
                }}
              >
                <Option key="0" value="all">
                  Tất cả
                </Option>
                <Option key="1" value="not-active">
                  Chưa kích hoạt
                </Option>
                <Option key="2" value="using">
                  Đang sử dụng
                </Option>
                <Option key="3" value="near-expired">
                  Sắp hết hạn
                </Option>
                <Option key="4" value="expired">
                  Đã hết hạn
                </Option>
                <Option key="5" value="clear-data">
                  Sắp xóa dữ liệu
                </Option>
              </Select>
            </FormControl>

            <FormControl sx={{ margin: "3px", width: "100%" }} size="md">
              <FormLabel>Số thành viên</FormLabel>
              <Select
                name="count_filter"
                size="sm"
                value={count_filter}
                sx={{ minWidth: 100 }}
                onChange={(e: any, newValue) => {
                  setCountFilter(newValue || status);
                }}
              >
                <Option key="0" value="all">
                  Tất cả
                </Option>
                <Option key="1" value="no">
                  = 0
                </Option>
                <Option key="2" value="yes">
                  &gt; 0
                </Option>
              </Select>
            </FormControl>
            <Box sx={{ margin: "3px", width: "100%" }}>
              <div style={{ height: "25px" }}></div>

              <Box
                sx={{
                  height: "2.3vh",
                  display: "flex",
                  width: {
                    xs: "100%",
                    lg: "170px",
                  },
                }}
              >
                <Button
                  size="sm"
                  startDecorator={<SearchIcon />}
                  onClick={() => handleSearch()}
                  sx={{
                    width: "90%",
                    marginRight: "5%",
                  }}
                >
                  Tìm kiếm
                </Button>
                <Tooltip title="Làm mới">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    sx={{
                      height: "2.3vh",
                      width: "5%",
                    }}
                    onClick={() => {
                      setName("");
                      setPackages("all");
                      setDomain("all");
                      setCountFilter("all");
                      setStatus("all");
                      setfromDate(undefined);
                      settoDate(undefined);
                      setTempSearch({
                        searchName: "",
                        package_id: "all",
                        status: "all",
                        domain: "all",
                        count_filter: "all",
                        fromDate: undefined,
                        toDate: undefined,
                      });
                      setCurrentPage(1);
                      setsearchClick(!searchClick);
                    }}
                  >
                    <RestartAltIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </div>
          <div className="ButtonDiv" style={{ display: "flex" }}>
            <FormControl sx={{ margin: "7px" }} size="sm">
              <div style={{ height: "2.3vh" }} />
              <Button
                size="sm"
                color="success"
                disabled={page <= 0}
                startDecorator={<DownloadIcon />}
                onClick={() => exportExcel()}
              >
                Xuất Excel
              </Button>
            </FormControl>
            <FormControl sx={{ margin: "7px" }} size="sm">
              <div style={{ height: "2.3vh" }} />
              <Button
                size="sm"
                startDecorator={<AddIcon />}
                onClick={() => navigate("/customer/addnew")}
              >
                Thêm mới
              </Button>
            </FormControl>
          </div>
        </Box>
        <Sheet
          // variant={count_list.length > 0 ? "outlined" : undefined}
          variant="outlined"
          sx={{
            marginTop: "10px",
            width: "100%",
            borderRadius: "md",
            overflow: "auto",
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            borderAxis="bothBetween"
            sx={(theme) => ({
              "& tr > *:last-child": {
                position: "sticky",
                right: 0,
                textAlign: "center",
                bgcolor: theme.variants.soft.neutral,
                maxWidth: "100px",
                width: "100px",
              },
              "& thead th": {
                color: "#096BDE",
                textAlign: "center",
                minWidth: "100px",
                height: "23px",
                margin: "auto",
                textOverflow: "initial",
                verticalAlign: "top",
                whiteSpace: "normal",
              },
              "& td": {
                textAlign: "center",
                width: "100px",
                height: "20px",
              },
            })}
            // style={{ tableLayout: laptopUI ? "fixed" : "auto" }}
            style={{ tableLayout: "auto" }}
          >
            <thead>
              {/* {count_list.length > 0 ? ( */}
              <tr>
                {count_list
                  .concat(list_package.map((x) => ({ _id: x._id, count: 0 })))
                  .reduce((acc: any, current: any) => {
                    const x = acc.find((item: any) => item._id === current._id);
                    if (!x) {
                      return acc.concat([current]);
                    } else {
                      return acc;
                    }
                  }, [])
                  .sort((a: any, b: any) => b.count - a.count)
                  .map((count: any) => (
                    <th key={count!._id}>
                      {list_package.find((x) => x._id == count!._id)
                        ? list_package.find((x) => x._id == count!._id)!
                            .package_name
                        : ""}
                    </th>
                  ))}
                <th>
                  <b>Tổng cộng</b>
                </th>
              </tr>
              {/* ) : (
                <></>
              )} */}
            </thead>
            <tbody>
              {/* {count_list.length > 0 ? ( */}
              <tr>
                {count_list
                  .concat(list_package.map((x) => ({ _id: x._id, count: 0 })))
                  .reduce((acc: any, current: any) => {
                    const x = acc.find((item: any) => item._id === current._id);
                    if (!x) {
                      return acc.concat([current]);
                    } else {
                      return acc;
                    }
                  }, [])
                  .sort((a: any, b: any) => b.count - a.count)
                  .map((count: any) => (
                    <td key={count!._id}>
                      <Link
                        underline="always"
                        onClick={(e) => {
                          setPackages(count!._id);
                          setTempSearch({
                            ...tempSearch,
                            package_id: count!._id,
                          });
                          setCurrentPage(1);
                          setsearchClick(!searchClick);
                        }}
                      >
                        {count!.count ? count!.count : 0}
                      </Link>
                    </td>
                  ))}
                <td>
                  <b>
                    <Link
                      underline="always"
                      textColor="common.black"
                      sx={{
                        textDecorationColor:
                          "var(--joy-palette-common-black, #0E0E10)",
                      }}
                      onClick={(e) => {
                        setPackages("all");
                        setTempSearch({
                          ...tempSearch,
                          package_id: "all",
                        });
                        setCurrentPage(1);
                        setsearchClick(!searchClick);
                      }}
                    >
                      {page}
                    </Link>
                  </b>
                </td>
              </tr>
              {/* ) : (
                <></>
              )} */}
            </tbody>
          </Table>
        </Sheet>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <Typography
            sx={{
              textAlign: "end",
              pr: "10px",
            }}
          >
            Tổng số bản ghi : {page}
          </Typography>
        </div>
        <Sheet
          variant="outlined"
          sx={{
            width: "100%",
            borderRadius: "md",
            flex: 1,
            minWidth: 350,
            overflow: "auto",
            minHeight: "63vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          {rows.length > 0 ? (
            <div
              className="DivTable"
              style={{ width: "100%", maxHeight: "63vh" }}
            >
              <Table
                className="CusTable"
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                borderAxis="bothBetween"
                sx={{
                  "& tr > *:last-child": {
                    position: "sticky",
                    right: 0,
                    bgcolor: "var(--TableCell-headBackground)",
                  },
                  "& tr > *:nth-of-type(11)": {
                    position: "sticky",
                    right: "137px",
                    bgcolor: "var(--TableCell-headBackground)",
                  },
                  "& th": {
                    color: "#096BDE",
                    height: "25px",
                  },
                }}
                style={{ tableLayout: "auto" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "4%", textAlign: "center" }}>STT</th>
                    <th style={{ width: "10%", textAlign: "center" }}>
                      Mã định danh
                    </th>
                    <th style={{ width: "15%", textAlign: "center" }}>
                      Dòng họ
                    </th>
                    <th style={{ width: "5%", textAlign: "center" }}>
                      Ngày đăng ký
                    </th>
                    <th style={{ width: "20%", textAlign: "center" }}>
                      Gói dịch vụ
                    </th>
                    <th style={{ width: "25%", textAlign: "center" }}>
                      Thông tin liên hệ
                    </th>
                    <th style={{ width: "5%", textAlign: "center" }}>
                      SL gói phụ
                    </th>
                    <th style={{ width: "5%", textAlign: "center" }}>Số TV</th>
                    <th style={{ width: "5%", textAlign: "center" }}>
                      Ngày bắt đầu
                    </th>
                    <th style={{ width: "5%", textAlign: "center" }}>
                      Ngày hết hạn
                    </th>
                    <th style={{ textAlign: "center" }}>Kích hoạt</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row: Customer, index: number) => (
                    <tr key={row._id}>
                      <td style={{ textAlign: "center" }}>
                        {(currentpage - 1) * 10 + index + 1}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {row!.family_alias}
                      </td>
                      <td>
                        {row!.family.family_name ? (
                          row!.family.family_name
                        ) : (
                          <i>Không rõ</i>
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <DisplayDate
                          value={
                            row!.family.create_at
                              ? row!.family.create_at.toString()
                              : undefined
                          }
                        />
                      </td>
                      <td>
                        {row!.product_package.find(
                          (x) => x._id == row!.family.package_id
                        ) ? (
                          row!.product_package!.find(
                            (x) => x._id == row.family.package_id
                          )!.package_name
                        ) : (
                          <i>Không rõ</i>
                        )}
                      </td>
                      <td style={{ wordWrap: "break-word" }}>
                        <div>
                          <b>Họ tên: </b>
                          {row!.full_name ? row!.full_name : <i>Không rõ</i>}
                        </div>
                        <div>
                          <b>Email: </b>
                          {row.email}
                        </div>
                        <div>
                          <b>Điện thoại: </b>
                          {row.phone}
                        </div>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {row!.family.package_extend
                          ? row!.family.package_extend.length
                          : 0}
                      </td>
                      <td style={{ textAlign: "right" }}>{row.count}</td>
                      <td style={{ textAlign: "center" }}>
                        <DisplayDate
                          value={
                            row!.family.package_start_date
                              ? row!.family.package_start_date.toString()
                              : undefined
                          }
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <DisplayDate
                          value={
                            row!.family.package_expiration_date
                              ? row!.family.package_expiration_date.toString()
                              : undefined
                          }
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {/* <DisplayDate
                          value={
                            row!.family.package_extend
                              ? row!.family.package_extend.length > 0
                                ? new Date(
                                    Math.min(
                                      ...row!.family.package_extend.map((e) =>
                                        new Date(e.expiration_date).getTime()
                                      )
                                    )
                                  ).toString()
                                : undefined
                              : undefined
                          }
                          defaultValue="Chưa có"
                        /> */}
                        {/* {row.family.status == 0? 'Không': 'Có'} */}
                        <CustomSwitch row={row} />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <GroupButton row={row} index={index} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                margin: "auto",
                height: "63vh",
              }}
            >
              <Typography level="body-lg">
                Không có kết quả phù hợp !
              </Typography>
            </div>
          )}
          <PopupRender>{popup}</PopupRender>
          <CommonPopupRender {...initPopup} />
        </Sheet>
        <Box sx={{ marginTop: "10px", display: { md: "block", xs: "none" } }}>
          <Pagination
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "auto",
            }}
            showFirstButton
            showLastButton
            count={page > 10 ? Math.ceil(page / 10) : 1}
            size="medium"
            color="primary"
            page={currentpage}
            onChange={handleChangePage}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            margin: "auto",
          }}
        >
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage === 1}
            onClick={() => {
              setCurrentPage(1);
              setsearchClick(!searchClick);
            }}
            sx={{ bgcolor: "background.surface" }}
          >
            <FirstPageIcon />
          </IconButton>
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage === 1}
            onClick={() => {
              setCurrentPage(currentpage - 1);
              setsearchClick(!searchClick);
            }}
            sx={{ bgcolor: "background.surface" }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography textAlign="center" sx={{ minWidth: 80 }}>
            {`Trang ${currentpage}`}
          </Typography>
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage == Math.ceil(page / 10) || rows.length == 0}
            onClick={() => {
              setCurrentPage(currentpage + 1);
              setsearchClick(!searchClick);
            }}
            sx={{ bgcolor: "background.surface" }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
          <IconButton
            size="sm"
            color="neutral"
            variant="plain"
            disabled={currentpage == Math.ceil(page / 10) || rows.length == 0}
            onClick={() => {
              setCurrentPage(Math.ceil(page / 10));
              setsearchClick(!searchClick);
            }}
            sx={{ bgcolor: "background.surface" }}
          >
            <LastPageIcon />
          </IconButton>
        </Box>

        <Dialog
          open={openDialog}
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="responsive-dialog-title">Khôi phục data</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography id="alert-dialog-description">
                {titleDialog}
              </Typography>
              <input accept=".gp4b" type="file" id="fileUpload" />
              <Typography
                component="p"
                sx={{ fontStyle: "italic", color: "red" }}
              >
                (*) Lưu ý: dữ liệu sẽ bị xoá và khôi phục từ file đã chọn
              </Typography>
              {errMsgDialog && (
                <Typography component="p" sx={{ color: "red" }}>
                  Error: {errMsgDialog}
                </Typography>
              )}
              {wattingDialog && <LinearProgress />}
              {doneFlag && (
                <Typography component="p" sx={{ color: "green" }}>
                  Khôi phục thành công
                </Typography>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button disabled={doneFlag} onClick={handleRestoreFile}>
              Xác nhận
            </Button>
            <Button onClick={handleClose} autoFocus>
              Thoát
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop
          sx={{
            background: "rgba(245, 245, 245, 0.6)",
            zIndex: 10000,
            backdropFilter: "blur(5px)",
          }}
          open={loading}
          transitionDuration={{
            appear: 1,
            exit: 1,
          }}
        >
          <CircularProgress thickness={4} size="lg" variant="plain" />
        </Backdrop>
      </div>
    </Box>
  );
}
